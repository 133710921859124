import VueRouter from "vue-router";
import Cadastro from "./views/cadastro/Cadastro.vue"
import Acesso from "./views/acesso/Acesso.vue"
import Codigo from "./views/acesso/Codigo.vue"
import ValidarAcesso from "./views/acesso/ValidarAcesso.vue"
import Home from "./views/publico/Home.vue"

const routes = [
 {
    path: "/",
    name: "home",
    component: Home,
    props: true,
  }, 

  {
    path: "/acesso",
    name: "acesso",
    component: Acesso,
  },   

  {
    path: "/acesso/validar",
    name: "validar-acesso",
    component: ValidarAcesso,
  },   

  {
    path: "/acesso/:code",
    name: "codigo",
    component: Codigo,
    props: (route) => ({ code: route.params.code })  
  },   

  {
    path: "/:slug",
    name: "ministerio",
    component: Cadastro,
    props: (route) => ({ slug: route.params.slug })  
  },   

];



const router = new VueRouter({
  routes: routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    } else {
      return savedPosition;
    } 
  },
});

export default router;
