import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuelidate from 'vuelidate'
import VueTheMask from 'vue-the-mask'
import VueRouter from 'vue-router'
import { BootstrapVue } from 'bootstrap-vue'
import VueSignaturePad from 'vue-signature-pad'

// Import Bootstrap an BootstrapVue CSS files (order is important)
//import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(VueTheMask)
Vue.use(VueRouter)
Vue.use(VueSignaturePad)
import moment from 'moment';
moment.locale('pt-br');
Vue.prototype.moment = moment

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),  
}).$mount('#app')



