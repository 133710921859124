<template>
    <div>
      <Pagina
        :ministerioNome="ministerio.nome.replace('IBAB ', '')"
        paginaNome="VOLUNTARIADO"
        :carregamentoTempo="1500"
      >
        

      Por favor, selecione o ministério desejado:

         
      <ul class="list-group ministerios-lista">
        <div v-for="ministerio in MINISTERIOS" :key="ministerio.id" :class="ministerio.slug">
            <a :href="ministerio.slug">
        <li class="list-group-item d-flex align-items-center">
            <div class="logo-miniatura"><logoIBAB /></div>
            {{ ministerio.nome }}</li></a>
        </div>
        </ul>


      </Pagina>
    </div>
  </template>
  
  <script>
    import LogoIBAB from "../../components/layout/LogoIBAB.vue";
  import Pagina from "../../components/layout/Pagina.vue";
  import { MINISTERIOS } from "@/constantes/ministerios";
  
  export default {
    components: {
      Pagina,
      LogoIBAB,
    },
  
    data() {
      return {
        MINISTERIOS,
        ministerio: {
            nome: 'ibab',
            slug: 'ibab-geral'
        },
      };
    },
  
    props: {
      slug: String,
    },
    mounted() {
      
  
    
    },
  };
  </script>
  