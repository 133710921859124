<template>
  <div>
    <Pagina
      :ministerioNome="ministerio.nome.replace('IBAB ', '')"
      paginaNome="VOLUNTARIADO"
      :carregamentoTempo="1500"
    >
      <Formulario :ministerio="ministerio" />
    </Pagina>
  </div>
</template>

<script>
import Formulario from "../../components/Formulario.vue";
import Pagina from "../../components/layout/Pagina.vue";
import { MINISTERIOS } from "@/constantes/ministerios";

export default {
  components: {
    Formulario,
    Pagina,
  },

  data() {
    return {
      MINISTERIOS,
      ministerio: null,
    };
  },

  props: {
    slug: String,
  },
  mounted() {
    const found = this.MINISTERIOS.find(
      (ministerio) => ministerio.slug == this.slug
    );

    if (found) {
      //console.log("Ministério encontrado", found);
      this.ministerio = found;
      const el = document.body;
      el.classList.add(this.slug);
    } else {
      console.error("Ministério não encontrado");
      window.location.href = "http://www.ibab.com.br";
    }
  },
};
</script>
