<template>
    <transition name="fade">
    <div class="ibab-splash">
        <Carregando />
    </div>
    </transition>
    
</template>

<script>
import Carregando from '@/components/layout/Carregando'

export default {
    components:{
        Carregando,
    }
}
</script>

<style lang="scss">
.ibab-splash{
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--cor);
    z-index: 100;
    #ibab-loading path, svg{
        fill: #fff!important;
        }
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>