<template>
  <div>
    <Pagina ministerioNome="Voluntários" paginaNome="Acesso" :carregamentoTempo="1500">
      <div class="carregamento-dados" v-if="submetendo">
        <Carregando />
      </div>

      <div v-if="erros">
        <h1>Erro</h1>
      </div>

      <div
        class="qrcode-area"
        :class="dados.situacao == 'EXPIRADO' ? 'expirado' : ''"
        v-show="gerado == true"
      >
        <div class="row d-flex">
          <div
            class="col-12 mb-3 mt-3"
            v-if="dados.situacao != 'ACESSO LIBERADO'"
          >
            <div
              v-if="dados.situacao == 'EXPIRADO'"
              class="alert alert-danger col-12 mb-3"
              role="alert"
            >
              Este código de acesso já expirou.
            </div>
            <div
              v-if="dados.situacao == 'EVENTO FUTURO'"
              class="alert alert-danger col-12 mb-3"
              role="alert"
            >
              Este código refere-se a um evento futuro ou que ainda não foi
              iniciado.
            </div>
          </div>

          <div class="qr col-lg-6 d-flex justify-content-center">
            <canvas id="canvas"></canvas>
          </div>

          <div class="col-lg-6">
            <div
              class="p-4 h-100 d-flex flex-column justify-content-between align-items-left text-left"
            >
              <p>
                Apresente este QR Code na portaria para ter acesso às
                instalações da IBAB.
              </p>

              <hr />

              <p>
                <b>{{ dados.nome }}</b
                ><br />
                <small>CPF: {{ dados.cpf }}</small
                ><br />
                <small>{{ dados.ministerio }}</small>
              </p>


              <div class="alert alert-warning">
                <div class="row">
                  <div class="col-2 d-flex align-items-center">
                    <h1><i class="lni lni-warning"></i></h1>
                  </div>
                  <div class="col-10">
                    <small
                      >Este código é de uso pessoal e intransferível, tendo
                      validade de {{ dados.inicio }} a {{ dados.fim }}.</small
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Pagina>
  </div>
</template>

<script>
import axios from "axios";
import Carregando from "../../components/layout/Carregando";
import Pagina from "../../components/layout/Pagina";
import QrCodeWithLogo from "qrcode-with-logos";

export default {
  components: {
    Pagina,
    Carregando,
  },

  data() {
    return {
      submetendo: true,
      gerado: false,
      dadosObtidos: false,
      erros: false,

      dados: {
        cpf: null,
        dataNascimento: null,
      },
    };
  },

  props: {
    code: String,
  },

  mounted() {
    this.consultar();
    const el = document.body;
    el.classList.add("acesso");
  },

  methods: {
    consultar() {
      this.submetendo = true;
      this.submetido = true;
      axios
        .post(process.env.VUE_APP_API_URL+"api/acesso/visualizar", {
          codigo: this.code,
        })
        .then((response) => {
          if (response.status == 200) {
            this.dados = response.data;
            this.dadosObtidos = true;
            this.submetendo = false;
          }
          this.gerarQrCode();
          this.gerado = true;
        })
        .catch((error) => {
          this.submetendo = false;
          this.erros = true;
          this.$router.push({ name: 'acesso' });
          console.log(error);
        });
    },

    gerarQrCode() {
      let qrcode = new QrCodeWithLogo({
        canvas: document.getElementById("canvas"),
        content: this.code,
        width: 380,
        //   download: true,
        image: document.getElementById("image"),
        logo: {
          src: "/imgs/logo-ibab-qrcode.png",
        },
      });

      qrcode.toCanvas().then(() => {
        qrcode.toImage().then(() => {
          setTimeout(() => {}, 2000);
        });
      });
    },
  },

  
};
</script>
