<template>
  <div>
    <Splash v-show="carregando" />
    <div class="ibab" v-show="!carregando">
      <div class="row flex-column-reverse flex-lg-row m-0">
        <div class="col-lg-7">
          <section class="conteudo">
            <header class="topo">
              <h2>
                {{ paginaNome }}
              </h2>

              <div class="logo-ministerio">
                <LogoIBAB />
                <h2>
                  {{ ministerioNome }}
                </h2>
              </div>
            </header>

            <slot name="default">

            </slot>
          </section>

          <Rodape />
        </div>

        <div class="col-lg-5 capa">
          <div
            class="overlay"           
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Rodape from "../Rodape.vue";
import Splash from "../Splash.vue";
import LogoIBAB from "../layout/LogoIBAB.vue";

export default {
  components: {
    Splash,
    Rodape,
    LogoIBAB,
  },

  data() {
    return {
      carregando: true,
    };
  },

  props: {
    ministerioNome: {
      type: String,
      default: "ibab-missao"
    },
    paginaNome: String,
    carregamentoTempo: Number,
  },

  mounted(){
    this.ministerioNome == null ? 'ibab' : this.ministerioNome;
      setTimeout(() => (this.carregando = false), this.carregamentoTempo);
  },
};
</script>
