export const MINISTERIOS = [
  {
    id: 1,
    nome: "Celebrando a Recuperação",
    slug: "celebrando",
  },
  {
    id: 2,
    nome: "IBAB Adolas",
    slug: "ibab-adolas",
  },
  {
    id: 3,
    nome: "IBAB Adulto",
    slug: "ibab-adulto",
  },
  {
    id: 4,
    nome: "IBAB Casa",
    slug: "ibab-casa",
  },
  {
    id: 5,
    nome: "IBAB Celebração",
    slug: "ibab-celebracao",
  },
  {
    id: 6,
    nome: "IBAB Cidade",
    slug: "ibab-cidade",
  },
  {
    id: 7,
    nome: "IBAB Criança",
    slug: "ibab-crianca",
  },
  {
    id: 8,
    nome: "IBAB Educação",
    slug: "ibab-educacao",
  },
  {
    id: 9,
    nome: "IBAB Família",
    slug: "ibab-familia",
  },
  {
    id: 10,
    nome: "IBAB Jovem",
    slug: "ibab-jovem",
  },
  {
    id: 11,
    nome: "IBAB Missão",
    slug: "ibab-missao",
  },
  {
    id: 12,
    nome: "IBAB Pastoral",
    slug: "ibab-pastoral",
  },  
  {
    id: 13,
    nome: "IBAB Comunicação",
    slug: "ibab-comunicacao",
  },
  {
    id: 14,
    nome: "IBAB Recepção",
    slug: "ibab-recepcao",
  },
  {
    id: 15,
    nome: "IBAB Pequenos Grupos",
    slug: "ibab-pgs",
  },
  {
    id: 16,
    nome: "IBAB FCN",
    slug: "ibab-fcn",
  },
  
];
