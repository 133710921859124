<template>
  <div class="row" refs="scrollToMe">
    <b-tabs
      pills
      content-class="mt-3 mb-5 d-flex justify-content-between"
      variant="dark"
    >
<!--        <b-tab
        title="Normas"
        :active="etapa == 0"
        @click="etapa = 0"
        :disabled="etapa < 0"
      >
        <template #title>
          <b-badge variant="dark">1</b-badge> Requisitos
        </template>


        <p>Quatro requisitos indispensáveis para participar das atividades presenciais na Ibab:</p>

        <ol class="destaque mt-4">
          <li>Se inscrever antecipadamente pelo aplicativo ibab.</li>
          <li>Apresentar comprovante de vacinação (carteira ou passaporte de vacinação), conforme determina o Decreto No. 60.488 de 27.08.2021. </li>
          <li>Usar  corretamente a máscara de proteção durante todo o período de permanência no espaço da igreja, conforme determina o Decreto Estadual No. 64.959 de 04.05.2020.</li>
          <li>Observar as normas de distanciamento físico (inclusive na utilização dos espaços comuns e durante a circulação).</li>
        </ol>

        <p class="mt-5">Se você está de acordo com os requisitos e deseja prosseguir com o seu cadastro, clique no item de confirmação abaixo:</p>


        <div
            class="alternativa mt-5"
            style="
              display: flex;
              align-items: center;
            "
          >
            <input
              class="option-input radio"
              type="radio"
              id="concorda"
              name="concorda"
              value="S"
              v-model="requisitos"
              style="top: 0"
            />
            <label for="concorda"           
              >Estou de acordo com os requisitos.</label
            >
          </div>

          <button class="btn btn-warning" @click="avancar()" :disabled="!requisitos">
          Avançar
        </button>


        </b-tab> -->
      <b-tab
        title="Identificação"
        :active="etapa == 1"
        @click="etapa = 1"
        :disabled="etapa < 1"
      >
        <template #title>
          <b-badge variant="dark">1</b-badge> Identificação
        </template>

        <h6 class="subtitulo">Termo de adesão ao trabalho voluntário</h6>
        <p>
          Prosseguindo, você aceita os termos abaixo:
        </p>

        <div
          class="form-control"
          style="
          border-radius: 1rem !important;
          height: auto;
          overflow-y: scroll;
          padding: 1rem;
          background: #fcfcfc;
        "
          readonly
        >
          <p>
            O trabalho voluntário a ser desempenhado junto a Igreja Batista de
            Água Branca, de acordo com a Lei nº 9.608 de 18/02/98, abaixo
            transcrita, é atividade não remunerada, e não gera vínculo
            empregatício nem funcional, ou quaisquer obrigações trabalhistas,
            previdenciárias ou afins. Declaro estar ciente da legislação
            específica e que aceito atuar como voluntário conforme este Termo de
            Adesão.
          </p>

          <div class="ml-2">
            <p>
              <b>Lei do Voluntariado nº 9.608, de 18.02.98.</b><br />Dispõe
              sobre o serviço voluntário e dá outras providências.
            </p>
            <p>
              O PRESIDENTE DA REPÚBLICA<br />
              Faço saber que o Congresso Nacional decreta e eu sanciono a
              seguinte Lei:
            </p>
            <p>
              Art. 1º - Considera-se serviço voluntário, para fins desta Lei, a
              atividade não remunerada, prestada por pessoa física a entidade
              pública de qualquer natureza ou instituição privada de fins não
              lucrativos, que tenha objetivos cívicos, culturais, educacionais,
              científicos, recreativos ou de assistência social, inclusive
              mutualidade. Parágrafo único: O serviço voluntário não gera
              vínculo empregatício nem obrigação de natureza trabalhista,
              previdenciária ou afim.
            </p>
            <p>
              Art. 2º - O serviço voluntário será exercido mediante a celebração
              de termo de adesão entre a entidade, pública ou privada, e o
              prestador do serviço voluntário, dele devendo constar o objeto e
              as condições do seu serviço.
            </p>
            <p>
              Art. 3º - O prestador do serviço voluntário poderá ser ressarcido
              pelas despesas que comprovadamente realizar no desempenho das
              atividades voluntárias. Parágrafo único: As despesas a serem
              ressarcidas deverão estar expressamente autorizadas pela entidade
              a que for prestado o serviço voluntário.
            </p>
            <p>Art. 4º - Esta lei entra em vigor na data de sua publicação.</p>
            <p>Art. 5º - Revogam-se as disposições em contrário.</p>
            <p>
              Brasília, 18 de fevereiro de 1998; 177 da Independência e 110 da
              República.<br /><br />FERNANDO HENRIQUE CARDOSO
            </p>
          </div>
          <p>
            As despesas a serem ressarcidas deverão antecipadamente ter
            autorização expressa. O presente Termo de Adesão estará em vigor até
            o final do presente ano, quando deverá ser renovado, caso seja de
            interesse de ambas as partes.
          </p>
        </div>

        <div class="row">
          <div class="form-group col-lg-6">
            <label for="cpf">CPF<span class="req">*</span></label>
            <input
              id="cpf"
              name="cpf"
              v-model="dados.cpf"
              v-mask="'###.###.###-##'"
              placeholder="000.000.000-00"
              type="text"
              class="form-control"
              :class="{
                'is-invalid': isValidCPF() == false,
              }"
            />
            <div v-if="isValidCPF() == false" class="invalid-feedback">
              O número de CPF informado não é válido.
            </div>
          </div>

          <div class="form-group col-lg-6">
            <label for="dataNascimento"
              >Data de nascimento<span class="req">*</span></label
            >
            <date-picker
              v-model="dados.dataNascimento"
              format="DD/MM/YYYY"
              valueType="format"
              placeholder="00/00/0000"
              input-class="form-control"
              v-mask="'##/##/####'"
            ></date-picker>
          </div>
        </div>

        <button class="btn btn-warning" @click="avancar()" :disabled="!etapa1">
          Avançar
        </button>
      </b-tab>

      <b-tab
        title="Dados pessoais"
        :disabled="etapa < 2"
        :active="etapa == 2"
        @click="etapa = 2"
      >
        <template #title>
          <b-badge variant="dark">2</b-badge> Dados pessoais
        </template>

        <div class="carregamento-dados" v-if="carregando">
          <Carregando />
        </div>

        <div class="row" v-if="!carregando">
          <h4><i class="lni lni-user"></i> Sobre você</h4>

          <div
            class="form-group col-lg-9"
            :class="{ 'form-group--error': $v.dados.nome.$error }"
          >
            <label for="nome">Nome completo<span class="req">*</span></label>
            <input
              name="nome"
              v-model.trim="$v.dados.nome.$model"
              type="text"
              class="form-control"
              :class="{
                'is-invalid': $v.dados.nome.$error,
              }"
              required
            />
            <div v-if="$v.dados.nome.$error" class="invalid-feedback">
              Por favor, informe o seu nome completo.
            </div>
          </div>

          <div
            class="form-group col-lg-3"
            :class="{ 'form-group--error': $v.dados.rg.$error }"
          >
            <label for="rg">RG<span class="req">*</span></label>
            <input
              id="rg"
              v-model.trim="$v.dados.rg.$model"
              name="rg"
              type="text"
              class="form-control"
              :class="{
                'is-invalid': $v.dados.rg.$error,
              }"
              required
            />
            <div v-if="$v.dados.rg.$error" class="invalid-feedback">
              Por favor, informe o seu RG.
            </div>
          </div>

          <div
            class="form-group col-lg-6"
            :class="{ 'form-group--error': $v.dados.email.$error }"
          >
            <label for="email">E-mail<span class="req">*</span></label>
            <input
              id="email"
              name="email"
              type="email"
              class="form-control"
              v-model.trim="$v.dados.email.$model"
              :class="{
                'is-invalid': $v.dados.email.$error,
              }"
              required
            />
            <div v-if="$v.dados.email.$error" class="invalid-feedback">
              Este endereço de e-mail não é válido.
            </div>
          </div>

          <div class="form-group col-lg-3">
            <label for="estadoCivil"
              >Estado Civil<span class="req">*</span></label
            >
            <select
              v-model.trim="dados.estadoCivil"
              name="estadoCivil"
              class="form-control"
              required
            >
              <option v-for="item in ec" :key="item.valor" :value="item.valor">
                {{ item.texto }}
              </option>
            </select>
          </div>

          <div class="form-group col-lg-3">
            <label for="genero">Gênero<span class="req">*</span></label>
            <select
              v-model="dados.genero"
              name="genero"
              class="form-control"
              required
            >
              <option value="F">Feminino </option>
              <option value="M">Masculino </option>
            </select>
          </div>

          <div class="form-group col-lg-6">
            <label for="escolaridade"
              >Escolaridade<span class="req">*</span></label
            >
            <select
              v-model="dados.escolaridade"
              name="escolaridade"
              class="form-control"
              required
            >
              <option
                v-for="item in TIPOS_ESCOLARIDADE"
                :value="item.valor"
                :key="item.valor"
              >
                {{ item.texto }}
              </option>
            </select>
          </div>

          <div
            class="form-group col-lg-6"
            :class="{ 'form-group--error': $v.dados.profissao.$error }"
          >
            <label for="profissao">Profissão<span class="req">*</span></label>
            <input
              id="profissao"
              name="profissao"
              v-model.trim="$v.dados.profissao.$model"
              :class="{
                'is-invalid': $v.dados.profissao.$error,
              }"
              type="text"
              class="form-control"
            />
            <div v-if="$v.dados.profissao.$error" class="invalid-feedback">
              Informe a sua profissão.
            </div>
          </div>

          <div class="form-group col-lg-4">
            <label for="numero"
              >Telefone celular<span class="req">*</span></label
            >
            <input
              v-model="dados.telefone1"
              name="numero"
              v-mask="['(##) ####-####', '(##) #####-####']"
              type="text"
              class="form-control"
              required
            />
          </div>

          <div class="form-group col-lg-4">
            <label for="numero">Telefone residencial</label>
            <input
              v-model="dados.telefone2"
              name="numero"
              v-mask="['(##) ####-####', '(##) #####-####']"
              type="text"
              class="form-control"
            />
          </div>

          <div class="form-group col-lg-4">
            <label for="numero">Telefone comercial</label>
            <input
              v-model="dados.telefone3"
              name="numero"
              v-mask="['(##) ####-####', '(##) #####-####']"
              type="text"
              class="form-control"
            />
          </div>

          <h4><i class="lni lni-home"></i> Endereço</h4>

          <div class="form-group col-lg-3 col-xl-2">
            <label for="cep">CEP<span class="req">*</span></label>
            <input
              v-model="dados.cep"
              v-mask="'#####-###'"
              name="cep"
              type="text"
              class="form-control"
              v-on:change="buscarCEP"
              required
            />
          </div>

          <div class="form-group col-lg-7">
            <label for="rua">Rua<span class="req">*</span></label>
            <input
              id="rua"
              name="rua"
              v-model="dados.rua"
              type="text"
              class="form-control"
              required
            />
          </div>

          <div class="form-group col-lg-3">
            <label for="complemento"
              >Complemento<span class="req">*</span></label
            >
            <input
              id="complemento"
              name="complemento"
              v-model="dados.complemento"
              type="text"
              class="form-control"
              required
            />
          </div>

          <div class="form-group col-lg-5">
            <label for="bairro">Bairro<span class="req">*</span></label>
            <input
              id="bairro"
              name="bairro"
              v-model="dados.bairro"
              type="text"
              class="form-control"
              required
            />
          </div>

          <div class="form-group col-lg-5">
            <label for="complemento">Cidade<span class="req">*</span></label>
            <input
              id="complemento"
              name="complemento"
              v-model="dados.cidade"
              type="text"
              class="form-control"
              required
            />
          </div>

          
          <div class="form-group col-lg-2">
            <label for="uf">UF<span class="req">*</span></label>
            <select
              v-model="dados.uf"
              name="uf"
              class="form-control"
              required
            >
              <option
                v-for="item in UFS"
                :value="item.sigla"
                :key="item.sigla"
              >
                {{ item.nome }}
              </option>
            </select>           
          </div>

          <h4><i class="lni lni-users"></i> Você e a IBAB</h4>

          <div class="form-group col-lg-6">
            <label for="cep"
              >Vínculo com a IBAB<span class="req">*</span></label
            >
            <select
              v-model="dados.vinculo"
              name="vinculo"
              class="form-control"
              required
            >
              <option
                v-for="item in TIPOS_VINCULO"
                :value="item.valor"
                :key="item.valor"
              >
                {{ item.texto }}
              </option>
            </select>
          </div>

          <div class="form-group col-lg-6">
            <label for="vinculoData"
              >Na IBAB desde<span class="req">*</span></label
            >
            <date-picker
              v-model="dados.vinculoData"
              format="DD/MM/YYYY"
              valueType="format"
              placeholder="00/00/0000"
              input-class="form-control"
              v-mask="'##/##/####'"
            ></date-picker>
          </div>

          <div class="form-group col-lg-6">
            <label for="pg"
              >Está integrado(a) em algum pequeno grupo?<span class="req"
                >*</span
              ></label
            >
            <select class="form-control" name="pg" id="pg" v-model="dados.pg">
              <option value="N">Não</option>
              <option value="S">Sim</option>
            </select>
          </div>

          <div class="form-group col-lg-6">
            <label for="pgfacilitador"
              >Em caso afirmativo, qual o nome do líder?</label
            >
            <input
              id="pgfacilitador"
              name="pgfacilitador"
              v-model="dados.pgfacilitador"
              type="text"
              class="form-control"
              :disabled="dados.pg === 'N'"
            />
          </div>
        </div>
        <button
          class="btn btn-warning"
          @click="avancar()"
          :disabled="!etapa2"
          v-if="!carregando"
        >
          Avançar
        </button>
      </b-tab>

      <b-tab
        title="Voluntariado"
        :active="etapa == 3"
        @click="etapa = 3"
        :disabled="etapa < 3"
      >
        <template #title>
          <b-badge variant="dark">3</b-badge> Voluntariado
        </template>

        <div class="row">
          <h4><i class="lni lni-grow"></i> Trabalho voluntário</h4>

          <div class="form-group col-lg-6">
            <label for="ministerio">Ministério<span class="req">*</span></label>
            <select
              v-model="dados.ministerio"
              :value="ministerio.id"
              name="ministerio"
              class="form-control"
              required
            >
              <option :value="ministerio.id" selected="true">{{
                ministerio.nome
              }}</option>
            </select>
          </div>

          <div class="form-group col-lg-6">
            <label for="funcao">Função<span class="req">*</span></label>
            <input
              id="funcao"
              name="funcao"
              v-model="dados.funcao"
              type="text"
              class="form-control"
            />
          </div>

          <div class="form-group col-12">
            <label for="diasHorarios"
              >Dias e horários<span class="req">*</span></label
            >
            <input
              id="diasHorarios"
              name="diasHorarios"
              v-model="dados.diasHorarios"
              type="text"
              class="form-control"
            />
          </div>

          <h4><i class="lni lni-handshake"></i> Assinatura</h4>

          <div class="col-12">
            <div class="assinatura row mt-4">
              <div class="assinar col-xl-6 col-12">
                <VueSignaturePad
                  :options="{
                    onBegin: () => {
                      $refs.signaturePad.resizeCanvas();
                    },
                  }"
                  max-width="400px"
                  height="300px"
                  ref="signaturePad"
                  class="signaturePad"
                  style="border: 1px solid #ddd; background: #fff; border-radius: 1rem;"
                />
              </div>
              <div class="informacoes col-xl-6 col-12">
                <p>
                  Na região em branco, faça a sua assinatura arrastando o mouse
                  ou dedo sobre a tela, caso esteja em um dispositivo com tela
                  sensível ao toque.
                </p>
                <p>Ao terminar, clique no botão <b>Confirmar assinatura</b></p>

                <button
                  @click="undo"
                  class="btn btn-light btn-block mb-2 btn-sm"
                >
                  <i class="lni lni-eraser"></i> Apagar assinatura
                </button>
                <button @click="save()" class="btn btn-success btn-block btn-sm">
                  <i class="lni lni-checkmark"></i> Confirmar assinatura
                </button>
              </div>
            </div>

            <div class="mt-4 mb-4"></div>
          </div>
        </div>

        <button class="btn btn-warning" @click="gravar" :disabled="gravando==true || etapa3==false">
          <i class="lni lni-checkmark-circle"></i> Concluir cadastro
        </button>

        <div
          class="resultados d-flex justify-content-center align-items-center"
        ></div>
      </b-tab>
    </b-tabs>

    <b-modal id="modal-resultados" title="" hide-footer centered>
      <div
        class="text-center d-flex justify-content-center flex-column carregamento-dados"
      >
        <Carregando v-if="gravando" />
        <p v-if="gravando">
          Estamos processando o seu cadastro... isto pode durar alguns segundos.
        </p>

        <Sucesso v-if="gravado" />
        <p v-if="gravado">Seus dados foram recebidos com sucesso.</p>

        <ErroIcone v-if="erros" class="mb-5" />
        <p v-if="erros">{{ erroMsg }}</p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import Carregando from "./layout/Carregando.vue";
import Sucesso from "./layout/Sucesso.vue";
import ErroIcone from "./layout/ErroIcone.vue";
import { required, minLength, email } from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";
import "../../node_modules/vue2-datepicker/index.css";
import "../../node_modules/vue2-datepicker/locale/pt-br";
import { UFS } from "../constantes/ufs";

export default {
  props: {
    ministerio: Object,
  },

  components: {
    Carregando,
    DatePicker,
    Sucesso,
    ErroIcone,
  },

  data() {
    return {
      etapa: 1,
      carregando: false,
      gravado: false,
      gravando: false,
      erros: false,

      TIPOS_VINCULO: [
        {
          valor: "F",
          texto: "Frequentador(a)",
        },
        {
          valor: "M",
          texto: "Membro",
        },
      ],

      UFS: UFS,

      TIPOS_ESCOLARIDADE: [
        { valor: "1", texto: "Ensino fundamental completo" },
        { valor: "2", texto: "Ensino fundamental incompleto" },
        { valor: "3", texto: "Ensino médio completo" },
        { valor: "4", texto: "Ensino médio incompleto" },
        { valor: "5", texto: "Ensino superior completo" },
        { valor: "6", texto: "Ensino superior incompleto" },
        { valor: "7", texto: "Pós-graduação completa" },
        { valor: "8", texto: "Pós-graduação incompleta" },
        { valor: "9", texto: "Mestrado em andamento" },
        { valor: "10", texto: "Mestrado completo" },
        { valor: "11", texto: "Doutorado em andamento" },
        { valor: "12", texto: "Doutorado completo" },
      ],
      ec: [
        { valor: "S", texto: "Solteiro(a)" },
        { valor: "N", texto: "Namorando" },
        { valor: "C", texto: "Casado(a)" },
        { valor: "D", texto: "Divorciado(a)" },
        { valor: "V", texto: "Viúvo(a)" },
      ],

      requisitos: '',


      dados: {
        rand: '0',
        requisitos: '',
        nome: null,
        cpf: null,
        rg: null,
        dataNascimento: new Date("YYYY-MM-DD"),

        estadoCivil: null,
        genero: null,

        cep: "",
        rua: null,
        complemento: null,
        bairro: null,
        cidade: null,
        uf: null,

        email: null,
        telefone: null,

        vinculo: null,
        vinculoData: new Date("YYYY-MM-DD"),

        ministerio: null,
        funcao: null,
        diasHorarios: null,

        escolaridade: null,
        profissao: null,

        assinatura: null,
        pg: null,
        pgfacilitador: null,
      },
    };
  },

  validations: {
    dados: {
      nome: {
        required,
        minLength: minLength(8),
      },
      rg: {
        required,
        minLength: minLength(6),
      },
      estadoCivil: {
        required,
      },
      email: {
        required,
        type: email,
        minLength: minLength(6),
      },
      profissao: {
        required,
        minLength: minLength(6),
      },
      dataNascimento: {
        required,
        minLength: minLength(6),
      },
    },
  },

  created() {
    //console.log("ministerio", this.ministerio);
    this.dados.ministerio = this.ministerio.id;
  },

  computed: {
    ativarBotao() {
      return true;
    },

    etapa1() {
      return this.isValidCPF() && this.dados.dataNascimento.length > 5
        ? true
        : false;
    },

    etapa2() {
      return this.dados.nome &&
        !this.$v.dados.nome.$error &&
        this.dados.rg &&
        this.dados.email &&
        !this.$v.dados.email.$error &&
        this.dados.estadoCivil &&
        this.dados.genero &&
        this.dados.escolaridade &&
        this.dados.profissao &&
        this.dados.cep &&
        this.dados.rua &&
        this.dados.complemento &&
        this.dados.complemento != ' ' &&
        this.dados.bairro &&
        this.dados.cidade &&
        this.dados.uf &&
        this.dados.vinculo &&
        this.dados.vinculoData.length > 8 &&
        this.dados.pg
        ? true
        : false;
    },

    etapa3() {
      return this.dados.ministerio!=null &&
      this.dados.funcao!=null &&
      this.dados.diasHorarios!=null &&
      this.dados.assinatura!=null 
      ? true
      : false;
    },
  },

  methods: {
    testar() {
      //console.log(this.dados);
    },

    isValidCPF() {
      let cpf = this.dados.cpf;
      if (cpf) {
        if (cpf.length > 13) {
          if (typeof cpf !== "string") return false;
          cpf = cpf.replace(/[^\d]+/g, "");
          if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;
          cpf = cpf.split("");
          const validator = cpf
            .filter((digit, index, array) => index >= array.length - 2 && digit)
            .map((el) => +el);
          const toValidate = (pop) =>
            cpf
              .filter(
                (digit, index, array) => index < array.length - pop && digit
              )
              .map((el) => +el);
          const rest = (count, pop) =>
            ((toValidate(pop).reduce(
              (soma, el, i) => soma + el * (count - i),
              0
            ) *
              10) %
              11) %
            10;
          return !(
            rest(10, 2) !== validator[0] || rest(11, 1) !== validator[1]
          );
        }
      } else return null;
    },

    avancar() {
      //console.log(this.dados);
      if (this.etapa === 1) {
        this.carregando = true;
        //console.log("cpf pré req", this.dados.cpf);
        this.consultarCadastro();
      }
      this.etapa++;
      window.scrollTo(0, 0);
    },

    gravar() {
      this.erros = null;
      this.gravando = true;
      this.$bvModal.show("modal-resultados");
      this.dados.requisitos = this.requisitos;

      axios
        .post(process.env.VUE_APP_API_URL+"api/voluntariado/cadastrar", this.dados)
        .then((resposta) => {
          this.gravando = false;
          if (resposta.status == 201) {
            this.gravado = true;
            this.undo();
          }

          else if (resposta.status == 422){
            this.erroMsg = "Ops! Ocorreu um erro ao gravar o seu cadastro. Verifique se todos os campos obrigatórios foram preenchidos."
          }

          if (this.gravado) {
            this.dados.cep = "";
            this.dados = [];
          }
        })
        .catch((erros) => {
          this.gravando = false;
          this.erros = true;
          this.erroMsg = 'Ops! Ocorreu um erro. Tente novamente, por favor.';

          if (erros.response.status == 422){
            this.erroMsg = "Ops! Ocorreu um erro ao gravar o seu cadastro. Verifique se todos os campos obrigatórios foram preenchidos."
          }

          console.log(erros);
        });
    },

    buscarCEP() {
      if (this.dados.cep.length > 8) {
        axios
          .get(
            "https://viacep.com.br/ws/" +
              this.dados.cep.replace("-", "") +
              "/json/"
          )
          .then((response) => {
            if (response.status === 200) {
              this.dados.cidade = response.data.localidade;
              this.dados.bairro = response.data.bairro;
              this.dados.rua = response.data.logradouro;
              this.dados.uf = response.data.uf;
            }
          });
      }
    },

    consultarCadastro() {
      axios
        .post(process.env.VUE_APP_API_URL+"api/voluntario/pesquisar", {
          cpf: this.dados.cpf,
          dataNascimento: this.dados.dataNascimento,
        })
        .then((response) => {
          if (response.status == 200) {
            //console.log(response.data);
            this.dados = response.data;
            this.dados.ministerio = this.ministerio.id;
            this.dados.vinculoData = this.dados.vinculoDataNovo;
          }
          this.carregando = false;
        })
        .catch((error) => {
          this.carregando = false;
          console.log(error);
        });
    },

    undo() {
      this.$refs.signaturePad.clearSignature();
    },

    save() {
      this.dados.funcao = this.dados.funcao + ' ';
      this.dados.funcao = this.dados.funcao.trim();
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();     
     
      if (!isEmpty) {
        //var canvas = document.querySelector("canvas");
        this.dados.assinatura = data;
      } else {
        alert("A assinatura está em branco.");
      }     
    },
  },
};
</script>
