<template>
  <div>
    <Pagina ministerioNome="Voluntários" paginaNome="Acesso" :carregamentoTempo="1500">
      <!-- Formulário -->
      <div v-if="!submetido">
        <p>Prezado(a) voluntário,</p>
        <p>
          Informe os seus dados abaixo para obter o seu QR-Code, que
          possibilitará seu acesso às instalações da IBAB.
        </p>

        <div class="row">
          <div class="form-group col-lg-6">
            <label for="cpf">CPF<span class="req">*</span></label>
            <input
              id="cpf"
              name="cpf"
              v-model="dados.cpf"
              v-mask="'###.###.###-##'"
              placeholder="000.000.000-00"
              type="text"
              class="form-control"
              :class="{
                'is-invalid': isValidCPF() == false,
              }"
            />
            <div v-if="isValidCPF() == false" class="invalid-feedback">
              O número de CPF informado não é válido.
            </div>
          </div>

          <div class="form-group col-lg-6">
            <label for="dataNascimento"
              >Data de nascimento<span class="req">*</span></label
            >
            <date-picker
              v-model="dados.dataNascimento"
              format="DD/MM/YYYY"
              valueType="format"
              placeholder="00/00/0000"
              input-class="form-control"
              v-mask="'##/##/####'"
            ></date-picker>
          </div>
        </div>

        <button class="btn btn-warning" @click="avancar()" :disabled="!etapa1">
          Avançar
        </button>
      </div>

      <div class="carregamento-dados" v-if="submetendo">
        <Carregando />
      </div>

      <div v-if="acessos">
        <h4 class="mb-4">Acessos autorizados</h4>

        <p>
          Clique na opção desejada para visualizar o QR Code que dará acesso às
          instalações da IBAB.
        </p>

        <div class="row">
          <div class="col-md-6" v-for="dado in acessosValidos" :key="dado.id">
            <div
              class="card m-1"
              :class="ministerio(dado.ministerio.nome)"
              @click="redirecionar(dado.id)"
            >
              <div class="row no-gutters">
                <div
                  class="col-2 d-flex align-items-center justify-content-center logo"
                >
                  <LogoIBAB class="logo-ibab"
                  />
                </div>
                <div class="col-9">
                  <div class="card-body">
                    <!-- <a :href="'/acesso/' + dado.id"> </a> -->
                    <h6 class="card-title">{{ dado.ministerio.nome }}</h6>
                    <small>
                      <i class="lni lni-checkmark mr-2"></i> Início:
                      {{ moment(dado.inicio).format('DD/MM/YYYY [às] HH:mm:ss') }}<br />
                      <i class="lni lni-close mr-2"></i> Fim: {{ moment(dado.fim).format('DD/MM/YYYY [às] HH:mm:ss') }}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="acessos == null && submetido == true && !submetendo">
        <p>Não há autorizações para este voluntário.</p>
      </div>

    </Pagina>
  </div>
</template>

<script>
import axios from "axios";
import Carregando from "../../components/layout/Carregando.vue";
import LogoIBAB from "../../components/layout/LogoIBAB.vue";
import DatePicker from "vue2-datepicker";
import Pagina from "../../components/layout/Pagina";

export default {
  components: {
    Carregando,
    LogoIBAB,
    DatePicker,
    Pagina,
  },

  data() {
    return {
      submetendo: false,
      submetido: false,
      gerado: false,
      dados: {
        cpf: null,
        dataNascimento: null,
      },

      acessos: undefined,
    };
  },

  props: {
    slug: String,
  },

  mounted() {
    const el = document.body;
    el.classList.add("acesso");
  },

  methods: {
    ministerio(string) {
      var r = string.toLowerCase();
      r = r.replace(new RegExp("[àáâãäå]", "g"), "a");
      r = r.replace(new RegExp("æ", "g"), "ae");
      r = r.replace(new RegExp("ç", "g"), "c");
      r = r.replace(new RegExp("[èéêë]", "g"), "e");
      r = r.replace(new RegExp("[ìíîï]", "g"), "i");
      r = r.replace(new RegExp("ñ", "g"), "n");
      r = r.replace(new RegExp("[òóôõö]", "g"), "o");
      r = r.replace(new RegExp("œ", "g"), "oe");
      r = r.replace(new RegExp("[ùúûü]", "g"), "u");
      r = r.replace(new RegExp("[ýÿ]", "g"), "y");

      return r
        .replace(/\s+/g, "-")
        .toLowerCase()
        .normalize("NFC");
    },

    isValidCPF() {
      let cpf = this.dados.cpf;
      if (cpf) {
        if (cpf.length > 13) {
          if (typeof cpf !== "string") return false;
          cpf = cpf.replace(/[^\d]+/g, "");
          if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;
          cpf = cpf.split("");
          const validator = cpf
            .filter((digit, index, array) => index >= array.length - 2 && digit)
            .map((el) => +el);
          const toValidate = (pop) =>
            cpf
              .filter(
                (digit, index, array) => index < array.length - pop && digit
              )
              .map((el) => +el);
          const rest = (count, pop) =>
            ((toValidate(pop).reduce(
              (soma, el, i) => soma + el * (count - i),
              0
            ) *
              10) %
              11) %
            10;
          return !(
            rest(10, 2) !== validator[0] || rest(11, 1) !== validator[1]
          );
        }
      } else return null;
    },

    avancar() {
      this.submetendo = true;
      this.submetido = true;
      axios
        .post(process.env.VUE_APP_API_URL + "api/acesso/pesquisar", {
          cpf: this.dados.cpf,
          dataNascimento: this.dados.dataNascimento,
        })
        .then((response) => {
          if (response.status == 200) {
            this.acessos = response.data;
          }
          this.submetendo = false;
        })
        .catch((error) => {
          this.submetendo = false;
          console.log(error);
        });
    },

    redirecionar(id) {
      this.$router.push({ path: "/acesso/" + id });
    },
  },

  computed: {
    etapa1() {
      return this.isValidCPF() && this.dados.dataNascimento.length > 5
        ? true
        : false;
    },

    acessosValidos() {
      return this.acessos.filter((c) => {
        return c.situacao != "EXPIRADO";
      });
    },
  },
};
</script>

