<template>
  <div>
    <b-modal
      id="modal-acesso"
      v-model="exibirModal"
      centered
      title="Situação"
      cancel-disabled
    >
      <div
        class="w-100 d-flex justify-content-center align-items-center flex-column text-center gap-15"
        v-if="isValid && dados"
      >
        <SucessoIcone />
        <h5>Acesso liberado.</h5>
        <p>
          {{ dados.nome }}<br /><small>{{ dados.ministerio }}</small>
        </p>
      </div>
      <div
        class="w-100 d-flex justify-content-center align-items-center flex-column gap-15"
        v-else
      >
        <ErroIcone />
        <h5>Acesso não permitido.</h5>
      </div>

      <template #modal-footer>
        <div class="w-100"></div>
      </template>
    </b-modal>

    <Pagina ministerioNome="Voluntários" paginaNome="Acesso" :carregamentoTempo="1500">
      <div>
        <div class="camera">
          <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit">
            <div
              v-if="validationPending"
              class="validation-pending carregamento-dados"
            >
              <Carregando />
            </div>
          </qrcode-stream>
        </div>
      </div>
    </Pagina>
  </div>
</template>

<script>
import axios from "axios";
import Carregando from "../../components/layout/Carregando.vue";
import SucessoIcone from "../../components/layout/SucessoIcone.vue";
import ErroIcone from "../../components/layout/ErroIcone.vue";
import { QrcodeStream } from "vue-qrcode-reader";
import Pagina from "../../components/layout/Pagina";

export default {
  components: {
    Carregando,
    QrcodeStream,
    SucessoIcone,
    ErroIcone,
    Pagina,
  },

  data() {
    return {
      carregando: false,
      submetido: false,
      dados: {
        situacao: "",
      },

      situacao: "",
      exibirModal: false,
      isValid: undefined,
      camera: "auto",
      result: null,
    };
  },

  mounted() {
    const el = document.body;
    el.classList.add("acesso");
  },

  methods: {
    onInit(promise) {
      promise.catch(console.error).then(this.resetValidationState);
    },

    resetValidationState() {
      this.isValid = undefined;
    },

    async onDecode(content) {
      this.result = content;
      this.turnCameraOff();
      this.fazerCheckin(content);
      // pretend it's taking really long
      //await this.timeout(4000);
      //this.isValid = content.startsWith('http')
      //this.turnCameraOn();
    },

    turnCameraOn() {
      this.camera = "auto";
      this.dados = null;
      this.situacao = null;
    },

    turnCameraOff() {
      this.camera = "off";
    },

    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },

    fazerCheckin(codigo) {
      axios
        .post(process.env.VUE_APP_API_URL + "api/acesso/checkin", {
          codigo: codigo,
        })
        .then((resposta) => {
          console.log(resposta.data);

          this.dados = resposta.data;
          this.situacao = this.dados.situacao;
          this.exibirModal = true;

          if (this.situacao === "ACESSO LIBERADO") {
            this.isValid = true;
          } else this.isValid = false;
        })
        .catch((erro) => {
          this.exibirModal = true;
          this.dados = erro.data;
          this.situacao = this.dados.situacao;
          this.isValid = false;
          console.log(erro);
        });

      setTimeout(() => {
        this.exibirModal = false;
        this.turnCameraOn();
      }, 3500);
    },
  },

  computed: {
    validationPending() {
      return this.isValid === undefined && this.camera === "off";
    },

    validationSuccess() {
      return this.isValid === true;
    },

    validationFailure() {
      return this.isValid === false;
    },
  },
};
</script>

<style scoped>
.validation-success,
.validation-failure,
.validation-pending {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.8);
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.validation-success {
  color: green;
}
.validation-failure {
  color: red;
}
</style>
